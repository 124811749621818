<template>
  <product title="Amazfit 智能手表 2 复仇者联盟系列限量版"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1499"
    :colors="colors"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/0uz1Kh"
    is-bus-city1
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'nexo-marvel',
  components: {
    Product
  },
  data () {
    return {
      slogan: '专属量子战衣配色白陶瓷机身 | 白色硅胶表带 | 专属动态表盘<br/> 4G LTE 自由通话上网 | 2.5D 水漾屏 | 内置独立 QQ、QQ 音乐 <br/> 蓝牙通话 | NFC公交门禁',
      colors: [
        '漫威版'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/nexo-marvel/swiper/1.png',
          CDN_URL + '/images/product/nexo-marvel/swiper/2.png',
          CDN_URL + '/images/product/nexo-marvel/swiper/3.png',
          CDN_URL + '/images/product/nexo-marvel/swiper/4.png'
        ]
      ],
      detailImages: [
        CDN_URL + '/images/product/nexo-marvel/detail/s01.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s02.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s03.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s04.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s05.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s06.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s07.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s08.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s09.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s10.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s11.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s12.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s13.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/nexo-marvel/detail/s14.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/nexo-marvel/detail/s15.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
